// 服务协议
<template>
  <div class="index">
    <div>
      <!-- banner -->
      <el-carousel height="450px" class="indexBanner">
        <el-carousel-item v-for="(item, index) in this.$store.getters.banner" :key="index">
          <img :src="item.pic" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
      <!-- 公告 -->
      <el-carousel height="50px" direction="vertical" class="indexNotice">
        <el-carousel-item v-for="(item, index) in $store.getters.rollList" :key="index">
          <h3 class="medium">
            <span>网站公告：</span> <span>{{ item.title }}</span>
            <span>{{ item.createTime }}</span>
            <span @click="handleCommandAS" style=" cursor: pointer;">更多<i class="el-icon-arrow-right"></i></span>
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
   
    <div class="container">
      <div class="title">
        {{brandlist.title}}
      </div>
      <div class="content" v-html="brandlist.content"></div>
    </div>
  </div>
</template>
<script>
import {apiGetArticleType,apiGetArticleByType} from '@/api/arcticle'
export default {
  name: "brand",
  data() {
    return {
      brandlist: {}
    };
  },
  mounted(){
    this.getTypeList()
    this.getArticleList()
  },
  methods:{
    handleCommandAS(){
      this.$router.push({name: 'joiningNotice'})
      this.$store.commit('SET_INFO',['menuIndex','joining'])
    },
    getTypeList(){
      apiGetArticleType().then(res=>{
        console.log(res);
      })
    },
    getArticleList(){
      apiGetArticleByType({cid: 596}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.brandlist = res.data.list[0]
        }
      })
    },
    //查看详情
    toDetail(id){
      console.log(id);
      this.$router.push({name:'articleDetail',query:{id,path:'/brand',title:'品牌中心'}})
    }
  }
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
}
.indexBanner,
.indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.breadcrumb {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 3px solid#f1f2f3;
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
::v-deep.container{
  padding: 20px 30px;
  text-align: left;
  .title{
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px dashed blue;
  }
  .content{
    padding: 20px 0;
    line-height: 40px;
  }
}
</style>