import {get, post} from './axios'
// import qs from 'qs'
//账号密码登录
export const apiAccountLogin = p => post('/api/front/login',p)

//获取文章分类列表
export const apiGetArticleType = p => get('/api/front/article/category/list',p)
//获取文章列表
export const apiGetArticleByType = (p) =>{
    if(p.limit){
        return get('/api/front/article/list/'+p.cid +'?page=' + p.page+'&limit='+p.limit)
    }else{
        return get('/api/front/article/list/'+p.cid )
    }
} 
export const apiGetArticeDetail = p => get('/api/front/article/info?id='+p.id )